
import Vue from 'vue';
import {Component, PropSync} from 'vue-property-decorator';

@Component
class Gallery extends Vue {
  @PropSync('images', {required: true})

  created() {

  }

  currentIndex = 0

  setCurrent(index) {
    this.currentIndex = index;
  }

  previousImage() {
    console.log(this.currentIndex);
    if(this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
      return;
    }

    this.currentIndex = this.images.length-1;
  }

  nextImage() {
    if(this.currentIndex < this.images.length-1) {
      this.currentIndex = this.currentIndex + 1;
      return;
    }

    this.currentIndex = 0;
  }

}

export default Gallery;
