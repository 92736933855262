
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class Footer extends Vue {
  get navigationItems() {
    return [
      {
        name: this.$t(`navigation.about`),
        link: '/about'
      },
      {
        name: this.$t(`navigation.apartments`),
        link: '/apartments'
      },
      {
        name: this.$t(`navigation.wellnessSpa`),
        link: '/wellness'
      },
      {
        name: this.$t(`navigation.swimmingPool`),
        link: '/pool'
      },
      {
        name: this.$t(`navigation.location`),
        link: '/location'
      },
    ];
  }
}

export default Footer;
