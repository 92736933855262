
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Gallery from "@/components/base/Gallery.vue";

@Component({
  components: {
    Gallery,
  }
})
class Pool extends Vue {
  modals = {
    gallery: false,
  };

  get galleryImages() {
    return [
      require('@/assets/images/gallery/pool/1.jpg'),
      require('@/assets/images/gallery/pool/2.jpg'),
      require('@/assets/images/gallery/pool/3.jpg'),
    ]
  }
}

export default Pool;
