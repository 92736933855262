
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
class ContactForm extends Vue {
  formData = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  }

}

export default ContactForm;
