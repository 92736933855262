
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ContactForm from "@/components/forms/ContactForm.vue";

@Component({
  components: {
    ContactForm,
  }
})class PageCover extends Vue {
  modals = {
    contact: false,
  }
}

export default PageCover;
