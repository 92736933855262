
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Carousel, Slide } from 'vue-carousel';
import Book from "@/components/forms/Book.vue";
import Gallery from "@/components/base/Gallery.vue";

@Component({
  components: {
    Carousel,
    Slide,
    Book,
    Gallery,
  }
})
class Apartments extends Vue {
  currentSlide = 0;

  modals = {
    book: false,
    gallery: false,
  }

  get apartments() {
    return [
      {
        id: 1,
        text: `${this.$t(`apartments.apartment`)} 1`,
        color: '#f4f0dd',
        navigationBg: '#8FD9A8',
        cover: require('@/assets/images/apartments/apartment1/cover.png'),
        description: this.$t(`apartments.apartment1.description`),
        galleryImages: [
          require('@/assets/images/gallery/apartment-1/1.jpg'),
          require('@/assets/images/gallery/apartment-1/2.jpg'),
          require('@/assets/images/gallery/apartment-1/3.jpg'),
          require('@/assets/images/gallery/apartment-1/4.jpg'),
          require('@/assets/images/gallery/apartment-1/5.jpg'),
          require('@/assets/images/gallery/apartment-1/6.jpg'),
        ],
        capacity: {
          img: require('@/assets/images/apartments/capacity_4.png'),
          number: '4',
        },
        icons: [
          {
            src: require('@/assets/images/amanities/smart-tv.png'),
            alt: 'Smart TV'
          },
          {
            src: require('@/assets/images/amanities/air-conditioning.png'),
            alt: 'Air Conditioning'
          },
          {
            src: require('@/assets/images/amanities/fridge.png'),
            alt: 'Fridge'
          },
          {
            src: require('@/assets/images/amanities/cutlery.png'),
            alt: 'Cutlery'
          },
          {
            src: require('@/assets/images/amanities/coffee.png'),
            alt: 'Coffee'
          },
          {
            src: require('@/assets/images/amanities/bedding.png'),
            alt: 'Bedding'
          },
          {
            src: require('@/assets/images/amanities/hair-dryer.png'),
            alt: 'Hair Dryer'
          },
          {
            src: require('@/assets/images/amanities/shampoo.png'),
            alt: 'Shampoo'
          },
        ],
      },
      {
        id: 2,
        text: `${this.$t(`apartments.apartment`)} 2`,
        color: '#EBE5D6',
        navigationBg: '#28B5B5',
        cover: require('@/assets/images/apartments/apartment2/cover.png'),
        description: this.$t(`apartments.apartment2.description`),
        galleryImages: [
          require('@/assets/images/gallery/apartment-2/1.jpg'),
          require('@/assets/images/gallery/apartment-2/2.jpg'),
          require('@/assets/images/gallery/apartment-2/3.jpg'),
          require('@/assets/images/gallery/apartment-2/4.jpg'),
          require('@/assets/images/gallery/apartment-2/5.jpg'),
          require('@/assets/images/gallery/apartment-2/6.jpg'),
          require('@/assets/images/gallery/apartment-2/7.jpg'),
        ],
        capacity: {
          img: require('@/assets/images/apartments/capacity_4.png'),
          number: '4',
        },
        icons: [
          {
            src: require('@/assets/images/amanities/smart-tv.png'),
            alt: 'Smart TV'
          },
          {
            src: require('@/assets/images/amanities/air-conditioning.png'),
            alt: 'Air Conditioning'
          },
          {
            src: require('@/assets/images/amanities/fridge.png'),
            alt: 'Fridge'
          },
          {
            src: require('@/assets/images/amanities/cutlery.png'),
            alt: 'Cutlery'
          },
          {
            src: require('@/assets/images/amanities/coffee.png'),
            alt: 'Coffee'
          },
          {
            src: require('@/assets/images/amanities/bedding.png'),
            alt: 'Bedding'
          },
          {
            src: require('@/assets/images/amanities/hair-dryer.png'),
            alt: 'Hair Dryer'
          },
          {
            src: require('@/assets/images/amanities/shampoo.png'),
            alt: 'Shampoo'
          },
        ],
      },
      {
        id: 3,
        text: `${this.$t(`apartments.apartment`)} 3`,
        color: '#ECE2C6',
        navigationBg: '#4B778D',
        cover: require('@/assets/images/apartments/apartment3/cover.png'),
        description: this.$t(`apartments.apartment3.description`),
        galleryImages: [
          require('@/assets/images/gallery/apartment-3/1.jpg'),
          require('@/assets/images/gallery/apartment-3/2.jpg'),
          require('@/assets/images/gallery/apartment-3/3.jpg'),
          require('@/assets/images/gallery/apartment-3/4.jpg'),
          require('@/assets/images/gallery/apartment-3/5.jpg'),
          require('@/assets/images/gallery/apartment-3/6.jpg'),
        ],
        capacity: {
          img: require('@/assets/images/apartments/capacity_2.png'),
          number: '2',
        },
        icons: [
          {
            src: require('@/assets/images/amanities/smart-tv.png'),
            alt: 'Smart TV'
          },
          {
            src: require('@/assets/images/amanities/air-conditioning.png'),
            alt: 'Air Conditioning'
          },
          {
            src: require('@/assets/images/amanities/fridge.png'),
            alt: 'Fridge'
          },
          {
            src: require('@/assets/images/amanities/cutlery.png'),
            alt: 'Cutlery'
          },
          {
            src: require('@/assets/images/amanities/coffee.png'),
            alt: 'Coffee'
          },
          {
            src: require('@/assets/images/amanities/bedding.png'),
            alt: 'Bedding'
          },
          {
            src: require('@/assets/images/amanities/hair-dryer.png'),
            alt: 'Hair Dryer'
          },
          {
            src: require('@/assets/images/amanities/shampoo.png'),
            alt: 'Shampoo'
          },
        ],
      },
    ];

  }

  toPrevSlide() {
    if (this.currentSlide === 0) return;

    this.currentSlide--;
  }

  toNextSlide() {
    if (this.currentSlide === this.apartments.length - 1) return;

    this.currentSlide++;
  }

  scrollToApartments() {
    const el = document.querySelector('#apartment');
    window.scrollTo({
      left: 0,
      top: el.offsetTop - 100,
      behavior: 'smooth'
    });
  }

  bookApartment() {
    this.modals.book = true;
  }
}

export default Apartments;
