
import Vue from 'vue';
import {Component, PropSync} from 'vue-property-decorator';

@Component
class Book extends Vue {
  @PropSync('apartment', {default: null})

  created() {

  }

  formData = {
    name: '',
    apartment: null,
    email: '',
    phone: '',
    arrivalDate: null,
    departureDate: null,
    adults: null,
    children: null,
    message: '',
  }

  get apartmentOptions() {
    return [
      {
        id: 1,
        value: 'Apartment 1',
        text: `${this.$t(`form.apartment`)} 1`,
      },
      {
        id: 2,
        value: 'Apartment 2',
        text: `${this.$t(`form.apartment`)} 2`,
      },
      {
        id: 3,
        value: 'Apartment 3',
        text: `${this.$t(`form.apartment`)} 3`,
      },
    ];
  }

  declineKeys(event, parameters){
    if (parameters.includes(event.key)) event.preventDefault();
  }

  mounted() {
    if(this.apartment) {
      const apartmentOption = this.apartmentOptions.find(a => {
        return a.id === this.apartment.id;
      });

      Vue.set(this.formData, 'apartment', apartmentOption.value);
    }
  }

}

export default Book;
