
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class Features extends Vue {
  get features() {
    return [
      {
        name: this.$t(`features.activities`),
        icon: require('@/assets/images/features/outdoor_activities.png'),
      },
      {
        name: this.$t(`features.silence`),
        icon: require('@/assets/images/features/silence.png'),
      },
      {
        name: this.$t(`features.pool`),
        icon: require('@/assets/images/features/pool.png'),
      },
      {
        name: this.$t(`features.relaxation`),
        icon: require('@/assets/images/features/relaxation.png'),
      },
    ];
  }
}

export default Features;
