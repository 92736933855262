
import Vue from 'vue';
import {Component, PropSync} from 'vue-property-decorator';

@Component
class LocationFeatures extends Vue {

  @PropSync('text', {required: true})

  created(){}

}

export default LocationFeatures;
