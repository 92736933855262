
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Book from "@/components/forms/Book.vue";
import ContactForm from "@/components/forms/ContactForm.vue";

@Component({
  components: {
    Book,
    ContactForm,
  }
})
class Contact extends Vue {
  modals = {
    book: false,
    contact: false,
  }
}

export default Contact;
