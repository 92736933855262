import en from './locales/en';
import ba from './locales/ba';


const locales = {
    en,
    ba,
};

export default locales;
