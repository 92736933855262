
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Book from "@/components/forms/Book.vue";

@Component({
  components: {
    Book,
  }
})
class Navigation extends Vue {
  isMobileMenuActive = false;

  modals = {
    book: false,
  }

  get navigationItems() {
    return [
      {
        name: this.$t(`navigation.about`),
        link: '/about'
      },
      {
        name: this.$t(`navigation.apartments`),
        link: '/apartments'
      },
      {
        name: this.$t(`navigation.wellnessSpa`),
        link: '/wellness'
      },
      {
        name: this.$t(`navigation.swimmingPool`),
        link: '/pool'
      },
      {
        name: this.$t(`navigation.location`),
        link: '/location'
      },
      {
        name: this.$t(`navigation.contact`),
        link: '/contact'
      },
    ];
  }

  get currentLang() {
    return this.$i18n.locale
  }

  setLanguage(lang) {
    if(lang === this.$i18n.locale) return;

    this.$i18n.locale = lang;
  }


  toggleMenu() {
    this.isMobileMenuActive = !this.isMobileMenuActive;
  }

  bookApartment() {
    this.modals.book = true;
  }
}

export default Navigation;
